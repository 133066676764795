<template>
  <div
    class="px-md-12 pt-md-12 px-3 pt-3"
    :style="{ 'height': $vuetify.breakpoint.smAndDown ? '' : '100vh' }"
    >
    <v-row
      class="text-left fill-width align-center fill-height ma-0 primary"
      >
      <v-col
        class="background rounded-t-xl fill-height"
        style="position: relative"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 100px 24px 100px' : 'min-height: 668px'"
        :class="$vuetify.breakpoint.mdAndUp ? 'rounded-bl-xl' : 'rounded-b-xl py-9'"
        cols="12"
        md="7"
        order="1"
        order-md="0"
        >
        <div
          class="text-md-h2 text-h3 font-weight-bold mb-md-16 mb-8 primary--text"
          >
          <div
            v-if="userType == 'partner'"
            >
            <div
              class="mb-3"
              >
              Comunidad digital <span v-if="$vuetify.breakpoint.smAndDown">de</span>
            </div>
            <span v-if="$vuetify.breakpoint.mdAndUp">de </span>
            <span
              class="primary accent--text rounded-lg px-3"
              >
              innovación
            </span>
            <br/>
            <span
              class="primary accent--text rounded-lg px-3"
              >
              comercial.
            </span>
          </div>

          <div
            v-else
            >
            <div
              class="mb-3"
              >
              Descubre pymes, locales, ofertas y experiencias en<span v-if="$vuetify.breakpoint.smAndDown">de</span>
            </div>
            <span
              class="primary accent--text rounded-lg px-3"
              >
              Partners
            </span>
            <br/>
            <span
              class="primary accent--text rounded-lg px-3"
              >
              Fans.
            </span>
          </div>
        </div>

        <div
          class="mt-md-12 mt-8"
          >
          <div
            v-if="userType == 'partner'"
            style="z-index: 10"
            :style="$vuetify.breakpoint.mdAndUp ? 'position: absolute' : 'position: relative'"
            class="primary--text text-h6 font-weight-medium"
            >
            Impulsamos el crecimiento y la digitalización de tu negocio. <br/>
            Si eres un emprendedor en busca de expandir tu alcance y aumentar tus ventas en línea, <br/> has llegado al lugar indicado.  <br/>
            Podrás crear tu ecommerce gratuito y formar <br/>
            parte de una red social que te conectará <br/>
            con una gran cantidad de clientes potenciales <br/>
            interesados en tus productos y servicios <br/> 
            con este nuevo canal de venta <br/>
          </div>

          <div
            v-else
            style="z-index: 10"
            :style="$vuetify.breakpoint.mdAndUp ? 'position: absolute' : 'position: relative'"
            class="primary--text text-h6 font-weight-medium"
            >


            ¡Bienvenido a PartnersFans! <br/>
            Conéctate con una amplia variedad de productos <br/> ofrecidos por Pymes locales de tu interés. <br/>

            Si eres un amante de las compras en línea <br/> podrás ver múltiples tiendas virtuales en un solo lugar <br/>

            Tendrás acceso a una red social donde podrás <br/> descubrir productos únicos, <br/> interactuar con emprendedores y obtener beneficios exclusivos. <br/>
          </div>

          <img
            :height="$vuetify.breakpoint.mdAndUp ? 'auto' : '300px'"
            v-if="$vuetify.breakpoint.mdAndUp"
            :width="$vuetify.breakpoint.mdAndUp ? '60%' : ''"
            style="position: absolute; right: 0; bottom: 0; z-index: 0"
            :src="require('@/assets/img/hero.png')"
            ></img>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="5"
        order="0"
        order-md="1"
        class="d-flex justify-end white fill-height rounded-t-xl"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 100px 24px 100px' : 'min-height: 668px'"
        :class="$vuetify.breakpoint.mdAndUp ? 'rounded-br-xl' : 'rounded-b-xl'"
        >
        <login></login>
      </v-col>
    </v-row>
  </div>
</template>

<!-- <template>
  <div
    class="px-md-12 pt-md-12 px-3 pt-3"
    :style="{ 'height': $vuetify.breakpoint.smAndDown ? '' : '100vh' }"
    >
    <v-row
      class="text-left fill-width align-center fill-height ma-0 primary"
      >
      <v-col
        class="background rounded-t-xl fill-height"
        style="position: relative"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 100px 24px 100px' : 'min-height: 668px'"
        :class="$vuetify.breakpoint.mdAndUp ? 'rounded-bl-xl' : 'rounded-b-xl py-9'"
        cols="12"
        md="7"
        order="1"
        order-md="0"
        >
        <div
          class="text-md-h2 text-h3 font-weight-bold mb-md-16 mb-8 primary--text"
          >
          <div
            class="mb-3"
            >
            Descubre pymes locales, 
          </div>
          ofertas y  
          <span
            class="primary accent--text rounded-lg px-3"
            >
            experiencias
          </span>
          <br/>
          <span
            class="primary accent--text rounded-lg px-3"
            >
            en PartnersFans
          </span>
        </div>

        <div
          class="mt-md-12 mt-8"
          >
          <div
            style="z-index: 10; position: absolute"
            class="primary--text text-h6 font-weight-medium"
            >
            ¡Bienvenido a PartnersFans!<br/>
            Conéctate con una amplia variedad de productos <br/> 
            ofrecidos por Pymes locales de tu interés. <br/>
            Si eres un amante de las compras en línea <br/>
            podrás ver múltiples tiendas virtuales <br/> 
            en un solo lugar <br/>
            Tendrás acceso a una red social donde podrás <br/>
            descubrir productos únicos, <br/>
            interactuar con emprendedores y <br/> 
            obtener beneficios exclusivos. <br/>
          </div>

          <img
            :height="$vuetify.breakpoint.mdAndUp ? 'auto' : '300px'"
            :width="$vuetify.breakpoint.mdAndUp ? '60%' : ''"
            style="position: absolute; right: 0; bottom: 0; z-index: 0"
            :src="require('@/assets/img/hero.png')"
            ></img>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="5"
        order="0"
        order-md="1"
        class="d-flex justify-end white fill-height rounded-t-xl"
        :style="$vuetify.breakpoint.mdAndUp ? 'padding: 100px 100px 24px 100px' : 'min-height: 668px'"
        :class="$vuetify.breakpoint.mdAndUp ? 'rounded-br-xl' : 'rounded-b-xl'"
        >
        <login></login>
      </v-col>
    </v-row>
  </div>
</template> -->

<script>
import Login from '@/components/login.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    Login
  },

  props: {
    userType:{
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn'])
  },

  methods: {
    redirect () {
      this.isLoggedIn ? this.$router.push('/admin') : this.$router.push('/signup')
    }
  }
}
</script>
