<template>
  <div class="primary">
    <v-dialog width="600" v-model="dialog">
      <v-card rounded="lg">
        <v-card-title
          class="justify-center"
          v-html="'¡Te damos la bienvenida!'"
        >
        </v-card-title>

        <v-img
          :max-height="$vuetify.breakpoint.mobile ? 250 : 350"
          contain
          :src="require('@/assets/onboarding/first.svg')"
        ></v-img>
        <v-card-text>
          <user-type @next="setUserType"></user-type>
        </v-card-text>
      </v-card>
    </v-dialog>
    <hero :userType="userType"></hero>
    <div>
      <about></about>
    </div>
    <contact :userType="userType"></contact>

    <v-btn
      fab
      bottom
      right
      depressed
      fixed
      color="accent"
      :style="{ opacity: opacity }"
      @click="scrollToTop"
    >
      <v-icon large>
        mdi-chevron-up
      </v-icon>
    </v-btn>

    <section
      style="height: fit-content !important; min-height: fit-content; padding: 0px"
    >
      <v-footer class="background rounded-t-xl px-3" padless>
        <div
          class="d-flex flex-column flex-md-row justify-space-between pa-3 fill-width text-center text-md-left"
          style="gap: 12px"
        >
          <div
            class="d-flex primary--text font-weight-medium"
            style="gap: 24px"
          >
            <div>
              Términos y condiciones
            </div>

            <div>
              Políticas de privacidad
            </div>
          </div>

          <div class="primary--text">
            ©PartnersFans todos los derechos reservados, 2022
          </div>
        </div>
      </v-footer>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/landing/Hero";
import UserType from "@/components/shared/onboarding/UserType";

const About = () => import("@/components/landing/About");
const Contact = () => import("@/components/landing/Contact");

export default {
  name: "Login",

  data: () => ({
    opacity: 0,
    dialog: true,
    userType: "fan"
  }),

  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.opacity = "0.9";
      } else {
        this.opacity = "0";
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    setUserType(ut) {
      this.userType = ut;
      this.dialog = false;
    }
  },

  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },

  components: {
    Hero,
    About,
    Contact,
    UserType
  }
};
</script>
