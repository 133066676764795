<template>
  <v-container
    class="justify-center d-flex fill-height align-center"
    style="max-width: 400px"
    fluid>

    <v-dialog
      v-model="validation"
      width="500"
      >
      <v-card>
        <v-card-title
          class="justify-center"
          >
          Valida tu correo
        </v-card-title>

        <v-card-text>
          Verifica tu casilla de correo para validar que la cuenta te pertenece. <span class="font-weight-medium">No te olvides de revisar la casilla de spam y promociones en caso de no encontrarlo</span>.
          <v-otp-input
            length="6"
            type="number"
            v-model="code"
            ></v-otp-input>

          <v-btn
            class="fill-width rounded-lg"
            large
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click.stop="validateCode"
            >
            Validar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card
      :loading="loading"
      :disabled="loading"
      rounded="xl"
      class="pb-4"
      elevation="4"
      width="350"
      >
      <v-card-title 
        style="width:100%; word-break: break-word" class="pb-0 d-flex justify-center font-weight-medium">
        Recuperar contraseña
      </v-card-title>
      <v-card-subtitle
        class="text-center mt-3 text-subtitle-1"
        >
        No te preocupes, a todos nos pasa.
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-form
          v-model="valid"
          lazy-validation
          ref="form"
          onSubmit="return false;"
          >
          <v-alert 
            class="fill-width"
            outlined
            v-model="alert.alert"
            type="error"
            transition="scale-transition">
            {{`${$t('errors.' + alert.message)}`}}
          </v-alert>

          <template
            v-if="!validated"
            >
            <v-text-field
              :label="$t('fields.email')"
              ref="email"
              solo
              flat
              outlined
              class="rounded-lg"
              v-model="user.email"
              type="text"
              @keyup.enter="next"
              :rules="[ v => !!v || 'El campo es obligatorio']">
            </v-text-field>
          </template>

          <template
            v-else
            >
            <v-text-field
              :label="$t('fields.email')"
              readonly
              solo
              flat
              outlined
              class="rounded-lg"
              :value="user.email"></v-text-field>
          </template>

          <v-text-field
            label="Nueva contraseña"
            v-if="validated"
            ref="password"
            solo
            flat
            outlined
            class="rounded-lg"
            v-model="user.password"
            :type="show_password ? 'text' : 'password'"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_password = !show_password"
            :rules="[ v => !!v || 'El campo es obligatorio']">
          </v-text-field>

          <v-text-field
            label="Confirmar contraseña"
            :disabled="!user.password"
            v-if="validated"
            ref="password_validation"
            solo
            flat
            outlined
            class="rounded-lg"
            v-model="password_validation"
            :type="show_password ? 'text' : 'password'"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_password = !show_password"
            :rules="[ 
              v => !!v || 'El campo es obligatorio',
              v => (v || '') == user.password || 'Las contraseñas no coinciden.'
            ]">
          </v-text-field>
        </v-form>

        <v-btn
          @click.stop="next"
          large
          style="width:100%;"
          class="rounded-lg mb-3"
          color="primary">
          <span>
            {{ validated ? 'Recuperar contraseña' : 'Validar e-mail' }}
          </span>
        </v-btn>

        <v-btn
          @click.stop="back"
          large
          style="width:100%;"
          class="rounded-lg"
          >
          Regresar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { UpdatePassword } from '@/graphql/mutations/users'
import { CreateCode, ValidateCode } from '@/graphql/mutations/validations'

export default {
  name: 'ForgotPassword',

  data: () => ({
    alert: {
      alert: false,
      message: 'user_not_found',
    },
    user: {
      email: null,
      password: null,
    },
    userId: null,
    valid: false,
    validation: false,
    validated: false,
    show_password: false,
    code: null,
    loading: false,
  }),

  methods:{
    validateEmail(){
      this.loading = true;
      this.errors = {}
      
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.loading = false
        return
      }

      this.$apollo.mutate({
        mutation: CreateCode,
        variables: {
          input: {
            email: this.user.email,
            restore: true
          }
        }
      }).then(res => {
        this.errors = {}
        this.loading = false
        this.validation = true
        this.userId = res.data.createCode.id
      }).catch(errors => {
        this.valid = true
        this.alert.alert = true
        this.alert.message = `${errors.graphQLErrors[0].extensions.field}_${errors.graphQLErrors[0].extensions.message}`
        this.loading = false
      })
    },

    validateCode () {
      this.loading = true;
      this.errors = {}
      
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.loading = false
        return
      }

      this.$apollo.mutate({
        mutation: ValidateCode,
        variables: {
          input: {
            email: this.user.email,
            code: this.code
          }
        }
      }).then(res => {
        this.errors = {}
        this.loading = false
        this.validation = false

        if (res.data.validateCode.success) {
          this.validated = true
        } else {
          this.valid = true
          this.alert.alert = true
          this.alert.message = "invalid_code"
        }
      }).catch(errors => {
        this.valid = true
        this.alert.alert = true
        this.alert.message = `${errors.graphQLErrors[0].extensions.field}_${errors.graphQLErrors[0].extensions.message}`
        this.loading = false

        this.validation = false
      })
    },

    editUser () {
      if (this.$refs.form.validate()) {
        this.loading = true

        this.$apollo.mutate({
          mutation: UpdatePassword,
          variables: {
            input: {
              attributes: this.user,
              email: this.user.email
            }
          }
        }).then ( res => {
          this.$router.push({ name: 'login' })
        })
      }
    },

    next () {
      if (this.validated) {
        this.editUser ()
      } else {
        this.validateEmail ()
      }
    },

    back () {
      this.$router.go(-1)
    }
  }
}
</script>
