<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    rounded="xl"
    width="360"
    class="py-12 mx-auto"
    flat
    color="transparent"
  >
    <template slot="progress">
      <v-progress-linear
        color="secondary"
        height="6"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-card-text
      style="height: calc(100%)"
      class="d-flex flex-column justify-space-between pa-0"
    >
      <div class="d-flex justify-center">
        <v-img
          contain
          width="250"
          :src="require('@/assets/full_logo.png')"
        ></v-img>
      </div>

      <div>
        <v-form v-model="valid" lazy-validation ref="form">
          <v-alert
            class="fill-width"
            outlined
            v-model="alert.alert"
            type="error"
            transition="scale-transition"
          >
            {{ `${$t("errors." + alert.message)}` }}
          </v-alert>
          <v-text-field
            :label="$t('fields.email')"
            ref="email"
            class="rounded-lg"
            v-model="login.email"
            @keyup.enter="focusPassword"
            type="text"
            :rules="[v => !!v || 'El campo es obligatorio']"
          >
          </v-text-field>

          <v-text-field
            id="password"
            :label="$t('fields.password')"
            ref="password"
            class="rounded-lg"
            v-model="login.password"
            :type="show_password ? 'text' : 'password'"
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            @keyup.enter="auth"
            @click:append="show_password = !show_password"
            :rules="[v => !!v || 'El campo es obligatorio']"
          >
          </v-text-field>
          <v-row>
            <v-col>
              <div class="text-center">
                <router-link to="/forgot_password" class="text-decoration-none">
                  {{ $t("actions.forgetPassword") }}
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="justify-center align-center flex-column mt-8">
          <v-col class="text-center">
            <v-btn
              @click.stop="auth"
              :disabled="loading"
              large
              depressed
              width="221"
              class="rounded-pill pl-1 pr-3 accent--text justify-start"
              color="primary"
            >
              <v-icon large class="mr-1" color="accent">
                mdi-arrow-right-circle
              </v-icon>
              {{ $t("actions.login").toUpperCase() }}
            </v-btn>

            <v-btn
              :to="{ name: 'login', hash: '#contacto' }"
              :disabled="loading"
              large
              depressed
              width="221"
              class="rounded-pill pl-1 pr-3 accent--text mt-3 justify-space-between"
              outlined
              color="primary"
            >
              <v-icon large class="mr-1" color="accent">
                mdi-help-circle
              </v-icon>
              Quiero saber más
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row>
          <v-col class="text-center mb-3">
            <div class="text-center primary--text">
              {{ $t("actions.notSignUp") }}
            </div>

            <div>
              <router-link
                :to="{ name: 'signup' }"
                class="text-decoration-none font-weight-bold text-h6 primary--text"
              >
                {{ $t("actions.signup") }}
              </router-link>
            </div>
          </v-col>
        </v-row>
        <terms disclaimer class="text-center"></terms>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { SignIn } from "@/graphql/mutations/users";

import Terms from "@/components/shared/Terms";

export default {
  name: "LoginComponent",
  data: () => ({
    loading: false,
    alert: {
      alert: false,
      message: "user_not_found"
    },
    valid: true,
    errorMessages: "",
    show_password: false,
    login: {
      email: "",
      password: ""
    }
  }),

  computed: {
    ...mapGetters(["url"])
  },

  methods: {
    focusPassword() {
      document.getElementById("password").focus();
    },
    auth() {
      if (this.valid) this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: SignIn,
            variables: {
              input: {
                credentials: this.login
              }
            }
          })
          .then(response => {
            this.$store.commit("login");
            localStorage.setItem("auth_token", response.data.signIn.token);
            localStorage.setItem(
              "prefetched_sub",
              response.data.signIn.user.selectedProfile.id
            );
            this.$store.commit("setCurrentUser", response.data.signIn.user);

            this.$router.go();
          })
          .catch(error => {
            this.loading = false;
            this.alert.message = `${error.graphQLErrors[0].extensions.field}_${error.graphQLErrors[0].extensions.message}`;
            this.alert.alert = true;
          });
      }
    }
  },

  components: { Terms }
};
</script>
